import { render, staticRenderFns } from "./AppInputTags.vue?vue&type=template&id=4dec198c&scoped=true&"
import script from "./AppInputTags.vue?vue&type=script&lang=js&"
export * from "./AppInputTags.vue?vue&type=script&lang=js&"
import style0 from "./AppInputTags.vue?vue&type=style&index=0&lang=scss&module=true&"
import style1 from "./AppInputTags.vue?vue&type=style&index=1&id=4dec198c&scoped=true&lang=css&"

var cssModules = {}
var disposed = false

function injectStyles (context) {
  if (disposed) return
  
        cssModules["$style"] = (style0.locals || style0)
        Object.defineProperty(this, "$style", {
          configurable: true,
          get: function () {
            return cssModules["$style"]
          }
        })
      
}


  module.hot && module.hot.dispose(function (data) {
    disposed = true
  })



        module.hot && module.hot.accept(["./AppInputTags.vue?vue&type=style&index=0&lang=scss&module=true&"], function () {
          var oldLocals = cssModules["$style"]
          if (oldLocals) {
            var newLocals = require("./AppInputTags.vue?vue&type=style&index=0&lang=scss&module=true&")
            if (JSON.stringify(newLocals) !== JSON.stringify(oldLocals)) {
              cssModules["$style"] = newLocals
              require("/codebuild/output/src2436986127/src/frontend/node_modules/vue-hot-reload-api/dist/index.js").rerender("4dec198c")
            }
          }
        })

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "4dec198c",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src2436986127/src/frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4dec198c')) {
      api.createRecord('4dec198c', component.options)
    } else {
      api.reload('4dec198c', component.options)
    }
    module.hot.accept("./AppInputTags.vue?vue&type=template&id=4dec198c&scoped=true&", function () {
      api.rerender('4dec198c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/elements/AppInputTags.vue"
export default component.exports